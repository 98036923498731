{
  "name": "tegeco-web-app",
  "version": "0.2.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --configuration=local",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "npx playwright test",
    "test:ui": "npx playwright test --ui",
    "lint": "eslint .",
    "lint:fix": "eslint . --fix ",
    "prettier": "prettier . --check",
    "prettier:fix": "prettier . --write ",
    "format": "npm run lint && npm run prettier",
    "format:fix": "npm run prettier:fix && npm run lint:fix"
  },
  "private": true,
  "dependencies": {
    "@angular-slider/ngx-slider": "^18.0.0",
    "@angular/animations": "^18.2.13",
    "@angular/cdk": "^18.2.14",
    "@angular/common": "^18.2.13",
    "@angular/compiler": "^18.2.13",
    "@angular/core": "^18.2.13",
    "@angular/forms": "^18.2.13",
    "@angular/material": "^18.2.14",
    "@angular/platform-browser": "^18.2.13",
    "@angular/platform-browser-dynamic": "^18.2.13",
    "@angular/router": "^18.2.13",
    "@azure/msal-angular": "^3.0.25",
    "@azure/msal-browser": "^3.26.1",
    "@types/quill": "^2.0.14",
    "dayjs": "^1.11.13",
    "hls.js": "^1.5.17",
    "npm": "^10.9.0",
    "quill": "^2.0.2",
    "rxjs": "^7.8.1",
    "tk-assets": "0.0.145",
    "tk-sass-common": "0.0.29",
    "tslib": "^2.8.0",
    "zone.js": "^0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.14",
    "@angular-eslint/builder": "^18.4.0",
    "@angular-eslint/eslint-plugin": "18.3.1",
    "@angular-eslint/eslint-plugin-template": "18.3.1",
    "@angular-eslint/schematics": "^18.3.1",
    "@angular-eslint/template-parser": "18.3.1",
    "@angular/cli": "^18.2.14",
    "@angular/compiler-cli": "^18.2.13",
    "@eslint/eslintrc": "^3.1.0",
    "@eslint/js": "^9.9.1",
    "@playwright/test": "^1.48.0",
    "@types/jasmine": "^5.1.4",
    "@types/jasminewd2": "~2.0.13",
    "@types/jest": "^29.5.13",
    "@types/node": "^20.11.16",
    "@typescript-eslint/eslint-plugin": "^8.1.0",
    "@typescript-eslint/parser": "^8.1.0",
    "autoprefixer": "^10.4.20",
    "eslint": "^9.9.1",
    "eslint-config-prettier": "^9.1.0",
    "postcss": "^8.4.39",
    "prettier": "^3.3.3",
    "prettier-plugin-css-order": "^2.1.2",
    "prettier-plugin-tailwindcss": "^0.6.8",
    "puppeteer": "^22.6.5",
    "tailwindcss": "^3.4.14",
    "typescript": "^5.5.4"
  }
}
